var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chapterForm"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"年级分类","rules":[
        { required: true, message: '请选择年级分类', trigger: 'blur' },
      ],"prop":"gradeType"}},[_c('v-select',{attrs:{"options":_vm.gradeList},model:{value:(_vm.form.gradeType),callback:function ($$v) {_vm.$set(_vm.form, "gradeType", $$v)},expression:"form.gradeType"}})],1),_c('el-form-item',{attrs:{"label":"科目分类","rules":[
        { required: true, message: '请选择科目分类', trigger: 'blur' },
      ],"prop":"courseType"}},[_c('v-select',{attrs:{"options":_vm.classes},model:{value:(_vm.form.courseType),callback:function ($$v) {_vm.$set(_vm.form, "courseType", $$v)},expression:"form.courseType"}})],1),_c('el-form-item',{attrs:{"label":"章节名称","rules":[
        { required: true, message: '请输入章节名称', trigger: 'blur' },
      ],"prop":"chapterTitle"}},[_c('v-input',{attrs:{"placeholder":"请输入章节名称","width":250,"maxlength":50},model:{value:(_vm.form.chapterTitle),callback:function ($$v) {_vm.$set(_vm.form, "chapterTitle", $$v)},expression:"form.chapterTitle"}})],1),_c('el-form-item',{attrs:{"label":"排序","rules":[{ required: true, message: '请输入排序', trigger: 'blur' }],"prop":"sortIndex"}},[_c('v-input',{attrs:{"placeholder":"请输入排序","width":250,"type":"number"},model:{value:(_vm.form.sortIndex),callback:function ($$v) {_vm.$set(_vm.form, "sortIndex", $$v)},expression:"form.sortIndex"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }